#not-afraid-SR{
width:100%;
margin-top:50%;
}
.name-hero{
    position: absolute;
    top:0%;
    text-align: left;
    line-height: 1.2em;
    font-family: oswald;
    letter-spacing: .1em;
    margin-left:5%;

}
.name-subhero{
    position: absolute;
    top: 10%;
    font-weight: 100;
    font-size: 26px;
    margin-left: 6%;
    text-align: left;
}


.overlay{
    width:100%;
    height:100%;
    opacity:.1;
    background-color: black;
}

@media only screen and (min-width: 680px) {
.image-container{
    display: inline-block;
}

.hero-container{
    display: flex;
}


    .name-hero{
        position: relative;
        font-size:50pt;
        font-weight:300;
        margin-top: 35%;

    }

    .name-subhero{
        position: relative;
        text-align: left;
        margin-left:5%;
        font-weight:100;
        font-size:16pt;
        font-family: roboto;
        margin-top:-10%;
    }
    .name-hero-div{
        display:inline-block;
        width:100%;
    }

    #not-afraid-SR{
        margin-top: 0%;
        width: 40%;
        vertical-align: middle;
        margin-left:10%;
    }

.overlay{
    width:100%;
    height:100%;
    opacity:.1;
    background-color: black;
}

}