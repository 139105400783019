ul{

  display: flex;
  flex-direction: row;
  list-style-type: none;
  justify-content: space-between;	
  background-color: transparent;
  margin-bottom:20px;
}

li{

margin-right:50px;
padding-top: 20px;

}
.bm-burger-button {
position: fixed;
width: 25px;
height: 20px;
right: 25px;
top: 25px;
}
.fullscreen-links{
display:none;
}
.bm-burger-bars {
background:black;
}

a.bm-item.nav-link{
  padding-top: 30px;
  font-size: 20pt;
  height: 60px;
  font-style: bolder;
}

.bm-overlay{
background: rgba(0, 0, 0, 0.8);
}

nav.bm-item-list {
position: fixed;
right: 0px;
background-color: rgba(0, 0, 0, 01.03);
margin-left: auto;
margin-right: auto;
background-size: cover;
left: 0px;
padding-top: 50px;
top:0px;
}



.bm-cross {
background-color: white;
height:50px;
width:50px;
background-size: cover;
}

.bm-cross-button {
position: absolute;
min-height: 50px;
min-width: 50px;
background-size: cover;
right:0px;
top:0px;
}

.navLinks{
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-size: 24pt;
  color: white;
}

.title{
  color:black;
}

.transcribed{
  padding-top:0px;
}

@media only screen and (min-width: 680px) {
.bm-burger-button {
    display: none;
}

.fullscreen-links{
    display: flex;
}

.fullscreen-links.home{
  flex-direction: column;
  align-items: flex-end;
}

.navLinks{
    color: rgb(50, 80, 46);
}
}

@media only screen and (min-width: 600px)
{
ul{

    display: flex;
    flex-direction: row;
    list-style-type: none;
    justify-content: space-evenly;	
    background-color: transparent;

    margin-bottom:20px;
}

li{

  margin-right:50px;
  padding-top: 20px;

}

.navLinks{
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    color: black;
    font-size: 14pt;
}
}

@media only screen and (min-width: 1100px) {
  .fullscreen-links.home{
    flex-direction: row;
  }
}