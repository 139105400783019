a{
    text-decoration:none;
}

.mediaLinksUl{
    display:flex;
    flex-direction: column;
}

.mediaLinksUl>li{
    font-size:16pt;
    font-family: roboto;
    margin-bottom:40px;
}

.article-title{
    font-weight: bold;
}