.picture-box{
    width:400px;
    height:400px;
}

.work-images{
    max-width: 100%;
    max-height: 100%;
    padding: 100px;
    margin-top: 100px;
}

.pictures-list{
    display: flex;
    flex-wrap: wrap;
}

.fullsize-pic{
    display:block;
    margin-left:auto;   
    margin-right:auto;
    padding:200px;
}

.pic-text {
    background-color: rgb(214, 201, 166);
    color: black;
    font-size: 16px;
    padding: 16px 32px;
  }



  .work-images {
    opacity: 1;
    display: block;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }

  .middle-of-pic {
    transition: .5s ease;
    opacity: 0;
    position: relative;
    text-align: center;
    margin-top: -84%;
    width: 50%;
    margin-left: 34%;
  }

  .picture-box:hover .work-images {
    opacity: 0.3;
  }
  .picture-box:hover .middle-of-pic {
    opacity: 1;
  }